<template>
  <el-tooltip 
    popper-class="el-tooltip" 
    :visible-arrow="false"
    :disabled="!isShowTooltip" 
    effect="dark" 
    :placement="placement"
  >
    <div slot="content" v-html="content"></div>
    <div ref="tooltipWrap" class="tooltip-wrap" @mouseover="handleMouseOver">
      <slot></slot>
    </div>
  </el-tooltip>
</template>

<script>
export default {
  name: 'TooltipElement',
  props: {
    content: {
      type: String,
      required: true
    },
    alwaysShow: {
      type: Boolean,
      default: false
    },
    placement: {
      type: String,
      default: 'left'
    }
  },
  data() {
    return {
      isShowTooltip: false,
    }
  },
  methods: {
    handleMouseOver() {
      const tag = this.$refs.tooltipWrap.firstChild
      if (this.alwaysShow) 
        this.isShowTooltip = true
      else
        this.isShowTooltip = this.onMouseOver(tag)
    },
    onMouseOver(tag) {
      const contentWidth = tag.offsetWidth
      const contentHeight = tag.offsetHeight
      const scrollWidth = tag.scrollWidth
      const scrollHeight = tag.scrollHeight
      return contentWidth < scrollWidth || contentHeight < scrollHeight
    },
  }
}
</script>

<style lang="scss" scoped>
.tooltip-wrap {
  display: flex;
  align-items: center;
}
</style>